import React from "react";
import { Container } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DataInput from "./components/DataInput";
import "./styles/App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App: React.FC = () => {
  return (
    <Router>
      <Container sx={{ paddingTop: 2, maxWidth: "100% !important" }}>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<DataInput />} />
        </Routes>
      </Container>
    </Router>
  );
};
export default App;
